import React from "react";
import "./GradientButton.css";

export const GradientButton = ({ text, url }) => {
	return (
		<a className="gradient_button" href={url}>
			<span>{text}</span>
		</a>
	);
};
