import React from "react";
import { ServicesScreenBody } from "./ServicesScreenBody";
import Layout from "../../components/Layout/Layout";

export const ServicesScreen = () => {
	return (
		<>
			<Layout>
				<ServicesScreenBody />
			</Layout>
		</>
	);
};
