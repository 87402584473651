import ReactDOMClient from "react-dom/client";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { ServicesScreen } from "./screens/ServicesScreen";
import Layout from "./components/Layout/Layout";
import { DigitalWorkplaceServices } from "./screens/Services/DigitalWorkplaceServices";
import { ITInfrastructureServices } from "./screens/Services/ITInfrastructureServices";
import { CloudOpsServices } from "./screens/Services/CloudOpsServices";
import { AppliedAndGenerativeAIServices } from "./screens/Services/AppliedAndGenerativeAIServices";
import { DigitalEngineeringServices } from "./screens/Services/DigitalEngineeringServices";
import { InfrastructureConsultingServices } from "./screens/Services/InfrastructureConsultingServices";
import { CyberSecurityServices } from "./screens/Services/CyberSecurityServices";
import { CustomerExperienceServices } from "./screens/Services/CustomerExperienceServices";
import { DataAndAnalyticsServices } from "./screens/Services/DataAndAnalyticsServices";
import { EnergyAndUtilityServices } from "./screens/Services/EnergyAndUtilityServices";
import { BankingAndInsurance } from "./screens/Industries/BankingAndInsurance";
import { Healthcare } from "./screens/Industries/Healthcare";
import { Manufacturing } from "./screens/Industries/Manufacturing";
import { ContactUs } from "./screens/ContactUs/ContactUs";
import { HomePageScreen } from "./screens/HomePage/HomePage";
import { Trend1 } from "./screens/Trends/Trend1";

const app = document.getElementById("app");

const root = ReactDOMClient.createRoot(app);

const NotFoundPage = () => {
	const notFoundStyle = {
		textAlign: "center",
		marginTop: "50px",
		fontSize: "24px",
		color: "#000",
	};

	return (
		<div style={notFoundStyle}>
			<h1 style={{ fontSize: "72px", marginBottom: "20px" }}>404</h1>
			<p>Oops! The page you're looking for isn't here.</p>
			<p>You might have the wrong address, or the page may have moved.</p>
		</div>
	);
};

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<HomePageScreen />} />
				<Route path="/services" element={<ServicesScreen />} />
				<Route
					path="/services/it-infrastructure"
					element={
						<Layout>
							<ITInfrastructureServices />
						</Layout>
					}
				/>
				<Route
					path="/services/cloudops"
					element={
						<Layout>
							<CloudOpsServices />
						</Layout>
					}
				/>
				<Route
					path="/services/digital-workplace"
					element={
						<Layout>
							<DigitalWorkplaceServices />
						</Layout>
					}
				/>
				<Route
					path="/services/applied-and-generative-ai"
					element={
						<Layout>
							<AppliedAndGenerativeAIServices />
						</Layout>
					}
				/>
				<Route
					path="/services/digital-engineering"
					element={
						<Layout>
							<DigitalEngineeringServices />
						</Layout>
					}
				/>
				<Route
					path="/services/infrastructure-consulting"
					element={
						<Layout>
							<InfrastructureConsultingServices />
						</Layout>
					}
				/>
				<Route
					path="/services/cyber-security"
					element={
						<Layout>
							<CyberSecurityServices />
						</Layout>
					}
				/>
				<Route
					path="/services/customer-experience"
					element={
						<Layout>
							<CustomerExperienceServices />
						</Layout>
					}
				/>
				<Route
					path="/services/data-and-analytics"
					element={
						<Layout>
							<DataAndAnalyticsServices />
						</Layout>
					}
				/>
				<Route
					path="/services/energy-and-utility"
					element={
						<Layout>
							<EnergyAndUtilityServices />
						</Layout>
					}
				/>
				<Route
					path="/industries/banking-and-insurance"
					element={
						<Layout>
							<BankingAndInsurance />
						</Layout>
					}
				/>
				<Route
					path="/industries/healthcare"
					element={
						<Layout>
							<Healthcare />
						</Layout>
					}
				/>
				<Route
					path="/industries/manufacturing"
					element={
						<Layout>
							<Manufacturing />
						</Layout>
					}
				/>
				<Route
					path="/trends/trend-1"
					element={
						<Layout>
							<Trend1 />
						</Layout>
					}
				/>
				<Route
					path="/contact-us"
					element={
						<Layout nofooter>
							<ContactUs />
						</Layout>
					}
				/>
				<Route path="*" element={<NotFoundPage />} />
			</Routes>
		</BrowserRouter>
	</React.StrictMode>
);
