import React, { useEffect, useRef, useState } from "react";
import "./IndustriesDetails.css";
import { useIsMobile } from "../../utils/functions";

export const IndustriesDetails = ({
	design_type,
	header_title,
	header_body,
	header_img,
	desc_1_desc,
	desc_1,
	desc_2_desc,
	desc_2,
	bg_img,
	prev_url,
	next_url,
}) => {
	const isMobile = useIsMobile();
	const headerStyle = {
		background: `url(${header_img})`,
	};
	const circle1 = {
		background:
			design_type === "design_1"
				? "radial-gradient(49.21% 54.09% at 50% 73.76%, rgba(34, 202, 142, 0.60) 0%, rgba(26, 142, 255, 0.60) 100%)"
				: design_type === "design_2"
				? "radial-gradient(41.76% 43.69% at 61.31% 71.55%, #24FFFF 0%, #009962 100%)"
				: "radial-gradient(31.36% 34.56% at 49.9% 50%, #22CA8E 0%, #5315A2 100%)",
		left:
			design_type === "design_1"
				? "calc(50vw - 50px)"
				: design_type === "design_2"
				? "calc(25vw - 50px)"
				: "-50px",
		top: design_type === "design_2" ? "-30px" : "10px",
		...(isMobile ? { display: "none" } : {}),
	};
	const circle2 = {
		background:
			design_type === "design_1"
				? "radial-gradient(49.21% 54.09% at 50% 73.76%, rgba(34, 202, 142, 0.60) 0%, rgba(26, 142, 255, 0.60) 100%)"
				: design_type === "design_2"
				? "radial-gradient(31.36% 34.56% at 49.9% 50%, #22CA8E 0%, #5315A2 100%)"
				: "radial-gradient(41.76% 43.69% at 61.31% 71.55%, #24FFFF 0%, #009962 100%)",
		left:
			design_type === "design_1"
				? "-60px"
				: design_type === "design_2"
				? "-60px"
				: "100px",
		bottom:
			design_type === "design_1"
				? "100px"
				: design_type === "design_2"
				? "50px"
				: "100px",
		...(isMobile ? { display: "none" } : {}),
	};
	const circle3 = {
		background:
			design_type === "design_1"
				? "radial-gradient(41.76% 43.69% at 61.31% 71.55%, #24FFFF 0%, #009962 100%)"
				: design_type === "design_2"
				? "radial-gradient(49.21% 54.09% at 50% 73.76%, rgba(34, 202, 142, 0.60) 0%, rgba(26, 142, 255, 0.60) 100%)"
				: "radial-gradient(31.36% 34.56% at 49.9% 50%, #22CA8E 0%, #5315A2 100%)",
		left:
			design_type === "design_1"
				? "-100px"
				: design_type === "design_2"
				? "calc(100vw - 100px)"
				: "-100px",
		bottom:
			design_type === "design_1"
				? "0"
				: design_type === "design_2"
				? "20px"
				: "20px",
		...(isMobile ? { display: "none" } : {}),
	};
	const circle4 = {
		background:
			design_type === "design_1"
				? "radial-gradient(31.36% 34.56% at 49.9% 50%, #22CA8E 0%, #5315A2 100%)"
				: design_type === "design_2"
				? "radial-gradient(41.76% 43.69% at 61.31% 71.55%, #24FFFF 0%, #009962 100%)"
				: "radial-gradient(49.21% 54.09% at 50% 73.76%, rgba(34, 202, 142, 0.60) 0%, rgba(26, 142, 255, 0.60) 100%)",
		left:
			design_type === "design_1"
				? "calc(100vw - 100px)"
				: design_type === "design_2"
				? "-100px"
				: "calc(100vw - 100px)",
		bottom:
			design_type === "design_1"
				? "0"
				: design_type === "design_2"
				? "20px"
				: "100px",
		...(isMobile ? { display: "none" } : {}),
	};
	const [maxHeights, setMaxHeights] = useState([]);
	const [maxHeights1, setMaxHeights1] = useState([]);
	const bodyRefs = useRef({});
	const bodyRefs1 = useRef({});
	useEffect(() => {
		const calculateMaxHeights = () => {
			document
				.querySelectorAll(".desc_1 .body_col .body")
				.forEach((b) => (b.style.height = ""));
			if (isMobile) {
				return;
			}
			const newMaxHeights = [];
			const elements = document.querySelectorAll(
				".desc_1 .body_col:first-child .body"
			);
			for (var row = 0; row < elements.length; row++) {
				const heights = [];
				for (var col = 0; col < 3; col++) {
					const index = row * 3 + col;
					const element = bodyRefs.current[index];
					if (element) {
						heights.push(element.offsetHeight);
					}
				}
				newMaxHeights[row] = Math.max(...heights);
			}
			for (var r = 0; r < elements.length; r++) {
				for (var col1 = 0; col1 < 3; col++) {
					const index = r * 3 + col1;
					const element = bodyRefs.current[index];
					if (element) {
						element.style.height = `${newMaxHeights[r]}px`;
					}
				}
			}
			setMaxHeights(newMaxHeights);
		};
		calculateMaxHeights();
		window.addEventListener("resize", calculateMaxHeights);
		return () => window.removeEventListener("resize", calculateMaxHeights);
	}, [desc_1, isMobile]);
	useEffect(() => {
		const calculateMaxHeights1 = () => {
			document
				.querySelectorAll(".desc_2 .body_col .body")
				.forEach((b) => (b.style.height = ""));

			document
				.querySelectorAll(
					".desc_2 .side_body.main .hr-hori:not(.mobile_only)"
				)
				.forEach((element) => element.remove());
			if (isMobile) {
				return;
			}
			const newMaxHeights = [];
			const elements = document.querySelectorAll(
				".desc_2 .body_col:first-child .body"
			);
			for (var row = 0; row < elements.length; row++) {
				const heights = [];
				for (var col = 0; col < 3; col++) {
					const index = row * 3 + col;
					const element = bodyRefs1.current[index];
					if (element) {
						heights.push(element.offsetHeight);
					}
				}
				newMaxHeights[row] = Math.max(...heights);
			}
			for (var row1 = 0; row1 < elements.length; row1++) {
				for (var c = 0; c < 3; c++) {
					const index = row1 * 3 + c;
					const element = bodyRefs1.current[index];
					if (element) {
						element.style.height = `${newMaxHeights[row1]}px`;
					}
				}
			}
			setMaxHeights1(newMaxHeights);

			let totalHeight = 0;
			const parent = document.querySelector(".desc_2 .side_body.main");
			for (var _row = 0; _row < elements.length - 1; _row++) {
				totalHeight += newMaxHeights[_row];
				const height = totalHeight - 75 / 2;
				const style = `position:absolute;left:0;top:${height}px;`;
				const newContent = `<div class="hr-hori" style="${style}"></div>`;
				parent.insertAdjacentHTML("afterbegin", newContent);
			}
		};
		calculateMaxHeights1();
		window.addEventListener("resize", calculateMaxHeights1);
		return () => window.removeEventListener("resize", calculateMaxHeights1);
	}, [desc_2, isMobile]);
	return (
		<div className="industry_page">
			<div className="industry_header">
				<div className="header_bg" style={headerStyle} />
				<div className="header_circle1" style={circle1} />
				<div className="header_circle2" style={circle2} />
				<div className="header_parent">
					<p className="title">{header_title}</p>
					<p className="body">{header_body}</p>
				</div>
			</div>
			<div className="hr-hori"></div>
			<div className="desc_1">
				<div className="circle1" style={circle3}></div>
				<div className="parent">
					<div className="desc_2_title">
						<div className="desc">Digital Offerings</div>
						<div class="gradient-line"></div>
					</div>
					<p>{desc_1_desc}</p>
				</div>

				<div className="side_body">
					<div className="body_col">
						{desc_1
							?.filter((_, index) => index % 3 === 0)
							.map((desc, rowIdx) => (
								<div
									className="body"
									ref={(el) =>
										(bodyRefs.current[rowIdx * 3 + 0] = el)
									}
									style={
										maxHeights[rowIdx]
											? {
													height: `${maxHeights[rowIdx]}px`,
											  }
											: {}
									}
								>
									<div className="flex title_parent">
										<div className="title">
											{desc?.title}
										</div>
										<div>{desc?.icon}</div>
									</div>
									<div className="t_body">{desc?.body}</div>
								</div>
							))}
					</div>
					{/* <div className="body_col">
						{desc_1
							?.filter((_, index) => (index - 1) % 3 === 0)
							.map((desc, rowIdx) => (
								<div
									className="body"
									ref={(el) =>
										(bodyRefs.current[rowIdx * 3 + 1] = el)
									}
									style={
										maxHeights[rowIdx]
											? {
													height: `${maxHeights[rowIdx]}px`,
											  }
											: {}
									}
								>
									<div className="flex title_parent">
										<div className="title">
											{desc?.title}
										</div>
										<div>{desc?.icon}</div>
									</div>
									<div className="t_body">{desc?.body}</div>
								</div>
							))}
					</div>
					<div className="body_col">
						{desc_1
							?.filter((_, index) => (index + 1) % 3 === 0)
							.map((desc, rowIdx) => (
								<div
									className="body"
									ref={(el) =>
										(bodyRefs.current[rowIdx * 3 + 2] = el)
									}
									style={
										maxHeights[rowIdx]
											? {
													height: `${maxHeights[rowIdx]}px`,
											  }
											: {}
									}
								>
									<div className="flex title_parent">
										<div className="title">
											{desc?.title}
										</div>
										<div>{desc?.icon}</div>
									</div>
									<div className="t_body">{desc?.body}</div>
								</div>
							))}
					</div> */}
				</div>
			</div>
			<div className="desc_2_parent">
				<div className="parent">
					<div className="desc_2_title">
						<div className="desc">Solutions</div>
						<div class="gradient-line"></div>
					</div>
					<p>{desc_2_desc}</p>
				</div>
				<div
					className="bg"
					style={{ background: `url(${bg_img})` }}
				></div>
				<div className="desc_2">
					<div className="parent_mobile">
						<div className="desc_2_title">
							<div className="desc">Solutions</div>
							<div class="gradient-line"></div>
						</div>
						<p>{desc_2_desc}</p>
					</div>
					<div className="side_body main">
						<div className="side_body">
							<div className="body_col">
								{desc_2
									?.filter((_, index) => index % 3 === 0)
									.map((desc, rowIdx) => (
										<>
											<div
												className="body"
												ref={(el) =>
													(bodyRefs1.current[
														rowIdx * 3 + 0
													] = el)
												}
												style={
													maxHeights1[rowIdx]
														? {
																height: `${maxHeights1[rowIdx]}px`,
														  }
														: {}
												}
											>
												<div className="flex title_parent">
													<div className="title">
														{desc?.title}
													</div>
													<div>{desc?.icon}</div>
												</div>
												<div className="t_body">
													{desc?.body}
												</div>
											</div>
											<div className="hr-hori mobile_only"></div>
										</>
									))}
							</div>
							<div className="body_col">
								{desc_2
									?.filter(
										(_, index) => (index - 1) % 3 === 0
									)
									.map((desc, rowIdx) => (
										<>
											<div
												className="body"
												ref={(el) =>
													(bodyRefs1.current[
														rowIdx * 3 + 1
													] = el)
												}
												style={
													maxHeights1[rowIdx]
														? {
																height: `${maxHeights1[rowIdx]}px`,
														  }
														: {}
												}
											>
												<div className="flex title_parent">
													<div className="title">
														{desc?.title}
													</div>
													<div>{desc?.icon}</div>
												</div>
												<div className="t_body">
													{desc?.body}
												</div>
											</div>
											<div className="hr-hori mobile_only"></div>
										</>
									))}
							</div>
							<div className="body_col">
								{desc_2
									?.filter(
										(_, index) => (index + 1) % 3 === 0
									)
									.map((desc, rowIdx) => (
										<>
											<div
												className="body"
												ref={(el) =>
													(bodyRefs1.current[
														rowIdx * 3 + 2
													] = el)
												}
												style={
													maxHeights1[rowIdx]
														? {
																height: `${maxHeights1[rowIdx]}px`,
														  }
														: {}
												}
											>
												<div className="flex title_parent">
													<div className="title">
														{desc?.title}
													</div>
													<div>{desc?.icon}</div>
												</div>
												<div className="t_body">
													{desc?.body}
												</div>
											</div>
											<div className="hr-hori mobile_only"></div>
										</>
									))}
							</div>
						</div>
					</div>
				</div>
			</div>
			{!isMobile && (
				<div className="industry_footer">
					<div className="circle1" style={circle4}></div>
					<div
						className="flex"
						style={{
							margin: "auto auto 40px auto",
						}}
					>
						<div className="prev_button_div">
							<a href={prev_url}>
								<button className="prev_button">Prev</button>
							</a>
						</div>
						<div style={{ marginRight: "30px" }}></div>
						<a href={next_url}>
							<button className="next_button">Next</button>
						</a>
					</div>
				</div>
			)}
		</div>
	);
};
