import React from "react";
import "./ServicesHeader.css";

export const ServicesHeader = () => {
	return (
		<div className="services_header">
			<p>
				From the initial consultation to the implementation phase and
				beyond, we are dedicated to providing unparalleled support.
			</p>
			<div className="circle1"></div>
			<div className="circle2"></div>
		</div>
	);
};
