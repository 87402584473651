import { HoverCard } from "../../components/HoverCard/HoverCard";
import header1 from "../../static/img/services/header_1.webp";
import header2 from "../../static/img/services/header_2.webp";
import header3 from "../../static/img/services/header_3.webp";
import header4 from "../../static/img/services/header_4.webp";
import header5 from "../../static/img/services/header_5.webp";
import header6 from "../../static/img/services/header_6.webp";
import header7 from "../../static/img/services/header_7.webp";
import header8 from "../../static/img/services/header_8.webp";
import header9 from "../../static/img/services/header_9.webp";
import header10 from "../../static/img/services/header_10.webp";
import { ServicesHeader } from "./ServicesHeader";
import "./ServicesScreenBody.css";
import React from "react";

export const ServicesScreenBody = () => {
	return (
		<div>
			<ServicesHeader />
			<div className="hover_card_parent">
				<HoverCard
					className="w-50 mobile-w-100"
					border={["top", "bottom", "right"]}
					image={header1}
					title="IT Infrastructure"
					desc="Safeguard your IT systems against unauthorized access and threats with our Security services."
					url="/services/it-infrastructure"
				/>
				<HoverCard
					className="w-50"
					border={["top", "bottom"]}
					image={header2}
					title="CloudOps"
					desc="Revolutionize efficiency, resulting in a 44% reduction in downtime and improved reliability."
					url="/services/cloudops"
				/>
				<HoverCard
					className="w-33 mobile-w-50"
					border={["bottom", "right"]}
					image={header3}
					title="Digital Workplace"
					desc="Experience 26% reduction in task completiontime and improved collaboration efficiency."
					url="/services/digital-workplace"
				/>
				<HoverCard
					className="w-33 mobile-w-100"
					border={["bottom", "right"]}
					image={header4}
					title="Applied & Generative AI"
					desc="Upgrade with data-driven Innovation and a boost in idea generation and problem-solving abilities."
					url="/services/applied-and-generative-ai"
				/>
				<HoverCard
					className="w-33 mobile-w-50"
					border={["bottom"]}
					image={header5}
					title="Digital Engineering"
					desc="Optimize your product and achieve a 25% reduction in development cycles."
					url="/services/digital-engineering"
				/>
				<HoverCard
					className="w-100 mobile-w-50 rightHoverCard"
					image={header6}
					title="Infrastructure Consulting"
					desc="Leveraging Comprehensive Data for a 30% Increase in Operational Efficiency"
					url="/services/infrastructure-consulting"
				/>
				<HoverCard
					className="w-50 mobile-w-100"
					border={["top", "bottom", "right"]}
					image={header7}
					title="Cyber Security"
					desc="Strengthening your security with solutions while empowering swift Response to threats"
					url="/services/cyber-security"
				/>
				<HoverCard
					className="w-50"
					border={["top", "bottom"]}
					image={header8}
					title="Customer Experience"
					desc="Elevate your service delivery for enhanced customer satisfaction."
					url="/services/customer-experience"
				/>
				<HoverCard
					className="w-50"
					border={["bottom", "right"]}
					image={header9}
					title="Data & Analytics"
					desc="Enabling your informed decisions with a 57% increase in analytical accuracy"
					url="/services/data-and-analytics"
				/>
				<HoverCard
					className="w-50 mobile-w-100"
					border={["bottom"]}
					image={header10}
					title="Energy & Utility"
					desc="Empowering you with advanced technology for optimized operations and sustainable growth."
					url="/services/energy-and-utility"
				/>
			</div>
		</div>
	);
};
