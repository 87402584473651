import React, { useState, useEffect, useRef } from "react";
import logo from "../../static/img/logo.webp";
import "./Header.css";
import { useIsMobile } from "../../utils/functions";

export const Header = ({ className }) => {
	const isMobile = useIsMobile();
	className = className ? `header ${className}` : "header";

	// Desktop Drowdown Start
	const [dropdownOpened, setDropdownOpened] = useState(false);
	const dropdownRef = useRef(null);
	const toggleRef = useRef(null);

	const toggleDropdown = (event) => {
		if (event.target.classList.contains("can_toggle"))
			setDropdownOpened(!dropdownOpened);
	};

	const handleClickOutside = (event) => {
		if (toggleRef.current && toggleRef.current.contains(event.target)) {
			return;
		}

		if (
			dropdownRef.current &&
			!dropdownRef.current.contains(event.target)
		) {
			setDropdownOpened(false);
		}
	};

	useEffect(() => {
		if (isMobile) return;
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [isMobile]);

	// Desktop Drowdown End

	// Mobile Drowdown Start
	const [navOpened, setNavOpened] = useState(false);
	const [dropdownMobileOpened, setDropdownMobileOpened] = useState(false);

	const toggleNavMobile = () => {
		setNavOpened(!navOpened);
	};

	const toggleDropdownMobile = (event) => {
		if (event.target.classList.contains("can_toggle"))
			setDropdownMobileOpened(!dropdownMobileOpened);
	};
	// Mobile Drowdown End

	return (
		<div>
			<div className={className}>
				<div className="logo">
					<a href="/">
						<img
							src={logo}
							alt="logo"
							width={"auto"}
							height={"40px"}
						/>
					</a>
				</div>
				<div className="nav">
					<span>
						<a href="/services">Services</a>
					</span>
					<span
						className={
							"dropdown can_toggle" +
							(dropdownOpened ? " opened" : "")
						}
						ref={toggleRef}
						onClick={toggleDropdown}
					>
						<span className="can_toggle">Industry</span>
						<div className="icon can_toggle"></div>
						<div
							className={
								"dropdown_menu" +
								(dropdownOpened ? " opened" : "")
							}
							ref={dropdownRef}
						>
							<div className="dropdown_options">
								<p>
									<a href="/industries/banking-and-insurance">
										Banking & Insurance
									</a>
								</p>
								<p>
									<a href="/industries/healthcare">
										Healthcare
									</a>
								</p>
								<p>
									<a href="/industries/manufacturing">
										Manufacturing
									</a>
								</p>
							</div>
						</div>
					</span>
					<span>
						<a href="/#faqs">FAQs</a>
					</span>
					<span>
						<a href="/contact-us">Contact Us</a>
					</span>
				</div>
				<div
					className={"nav_icon" + (navOpened ? " opened" : "")}
					onClick={toggleNavMobile}
				>
					<svg
						className="icon_open can_toggle"
						xmlns="http://www.w3.org/2000/svg"
						width="23"
						height="16"
						viewBox="0 0 23 16"
						fill="none"
					>
						<path
							d="M1 7.29989C0.613401 7.29989 0.3 7.61329 0.3 7.99989C0.3 8.38649 0.613401 8.69989 1 8.69989V7.29989ZM21.9974 8.69989C22.384 8.69989 22.6974 8.38649 22.6974 7.99989C22.6974 7.61329 22.384 7.29989 21.9974 7.29989V8.69989ZM1 14.296C0.613401 14.296 0.3 14.6094 0.3 14.996C0.3 15.3826 0.613401 15.696 1 15.696V14.296ZM21.9974 15.696C22.384 15.696 22.6974 15.3826 22.6974 14.996C22.6974 14.6094 22.384 14.296 21.9974 14.296V15.696ZM1 0.3C0.613401 0.3 0.3 0.613401 0.3 1C0.3 1.3866 0.613401 1.7 1 1.7V0.3ZM21.9974 1.7C22.384 1.7 22.6974 1.3866 22.6974 1C22.6974 0.613401 22.384 0.3 21.9974 0.3V1.7ZM1 8.69989H21.9974V7.29989H1V8.69989ZM1 15.696H21.9974V14.296H1V15.696ZM1 1.7H21.9974V0.3H1V1.7Z"
							fill="white"
						/>
					</svg>
					<svg
						className="icon_close can_toggle"
						xmlns="http://www.w3.org/2000/svg"
						width="17"
						height="17"
						viewBox="0 0 17 17"
						fill="none"
					>
						<path
							d="M1.72174 14.4748C1.33114 14.8652 1.33102 15.4984 1.72147 15.889C2.11192 16.2796 2.74508 16.2797 3.13568 15.8892L1.72174 14.4748ZM9.51068 9.51667C9.90128 9.12622 9.9014 8.49306 9.51095 8.10246C9.1205 7.71186 8.48734 7.71174 8.09674 8.10219L9.51068 9.51667ZM8.09633 8.10028C7.70596 8.49095 7.7062 9.12412 8.09687 9.51449C8.48755 9.90487 9.12071 9.90463 9.51109 9.51395L8.09633 8.10028ZM15.8837 3.13652C16.274 2.74585 16.2738 2.11269 15.8831 1.72231C15.4924 1.33194 14.8593 1.33218 14.4689 1.72285L15.8837 3.13652ZM9.51068 8.10219C9.12008 7.71174 8.48692 7.71186 8.09647 8.10246C7.70602 8.49306 7.70614 9.12622 8.09674 9.51667L9.51068 8.10219ZM14.4717 15.8892C14.8623 16.2797 15.4955 16.2796 15.886 15.889C16.2764 15.4984 16.2763 14.8652 15.8857 14.4748L14.4717 15.8892ZM8.09647 9.51409C8.48692 9.90469 9.12008 9.90481 9.51068 9.51436C9.90128 9.12391 9.9014 8.49074 9.51095 8.10014L8.09647 9.51409ZM3.13595 1.72272C2.7455 1.33212 2.11234 1.332 1.72174 1.72245C1.33114 2.1129 1.33102 2.74606 1.72147 3.13666L3.13595 1.72272ZM3.13568 15.8892L9.51068 9.51667L8.09674 8.10219L1.72174 14.4748L3.13568 15.8892ZM9.51109 9.51395L15.8837 3.13652L14.4689 1.72285L8.09633 8.10028L9.51109 9.51395ZM8.09674 9.51667L14.4717 15.8892L15.8857 14.4748L9.51068 8.10219L8.09674 9.51667ZM9.51095 8.10014L3.13595 1.72272L1.72147 3.13666L8.09647 9.51409L9.51095 8.10014Z"
							fill="white"
						/>
					</svg>
				</div>
				<div className={"nav_mobile" + (navOpened ? " opened" : "")}>
					<span>
						<a href="/services">Services</a>
					</span>
					<span
						className={
							"dropdown_mobile can_toggle" +
							(dropdownMobileOpened ? " opened" : "")
						}
						onClick={toggleDropdownMobile}
					>
						<div className="can_toggle title">
							Industry
							<div className="icon_mobile can_toggle"></div>
						</div>

						<div
							className={
								"dropdown_menu_mobile" +
								(dropdownMobileOpened ? " opened" : "")
							}
						>
							<div className="dropdown_options_mobile">
								<p>
									<a href="/industries/banking-and-insurance">
										Banking & Insurance
									</a>
								</p>
								<p>
									<a href="/industries/healthcare">
										Healthcare
									</a>
								</p>
								<p>
									<a href="/industries/manufacturing">
										Manufacturing
									</a>
								</p>
							</div>
						</div>
					</span>
					<span>
						<a href="/#faqs">FAQs</a>
					</span>
					<span>
						<a href="/contact-us">Contact Us</a>
					</span>
				</div>
			</div>
		</div>
	);
};
