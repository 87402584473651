import "./Footer.css";
import GE3S from "../../static/img/GE3S.svg";
import { FooterContact } from "./FooterContact";
import React from "react";
export const Footer = () => {
	return (
		<div className="footer">
			<div className="head_footer_div">
				<img
					src={GE3S}
					className="logo"
					alt="logo"
					width={"60px"}
					height={"auto"}
				/>
				<p className="reach_msg">Reach out to us</p>
				<FooterContact
					country="United States of America"
					numbers={["+1 213-599-7399"]}
				/>
				<FooterContact
					country="United Arab Emirates"
					numbers={["+97124451337", "+971 45674907"]}
				/>
				<FooterContact country="Canada" numbers={["404 667 3372"]} />
				<FooterContact
					country="Saudi Arabia"
					numbers={["+96650482883"]}
				/>
				<FooterContact country="India" numbers={["+91 9837047518"]} />
			</div>
			<div className="head_footer_div footer_right">
				<div className="mr-160">
					<p className="head">Industries</p>
					<p>
						<a href="/industries/banking-and-insurance">
							Banking & Insurance
						</a>
					</p>
					<p>
						<a href="/industries/healthcare">Healthcare</a>
					</p>
					<p>
						<a href="/industries/manufacturing">Manufacturing</a>
					</p>
				</div>
				<div>
					<p className="head">Services</p>
					<p>
						<a href="/services/it-infrastructure">
							IT Infrastructure
						</a>
					</p>
					<p>
						<a href="/services/cloudops">CloudOps</a>
					</p>
					<p>
						<a href="/services/digital-workplace">
							Digital Workplace
						</a>
					</p>
					<p>
						<a href="/services/applied-and-generative-ai">
							Applied and generative AI
						</a>
					</p>
					<p>
						<a href="/services/digital-engineering">
							Digital Engineering
						</a>
					</p>
					<p>
						<a href="/services/infrastructure-consulting">
							Infrastructure Consulting
						</a>
					</p>
					<p>
						<a href="/services/cyber-security">Cyber Security</a>
					</p>
					<p>
						<a href="/services/customer-experience">
							Customer Experience
						</a>
					</p>
					<p>
						<a href="/services/data-and-analytics">
							Data & Analytics
						</a>
					</p>
					<p>
						<a href="/services/energy-and-utility">
							Energy & Utility
						</a>
					</p>
				</div>
			</div>
		</div>
	);
};
