import "./ContactUs.css";
import React from "react";
import { ContactUsContact } from "./ContactUsContact";

export const ContactUs = () => {
	return (
		<div className="contactus">
			<div className="header_circle1"></div>
			<div className="header_circle2"></div>
			<div className="contactus_header">Reach Out To Us</div>
			<div className="hr-hori"></div>
			<div className="contactus_contacts">
				<ContactUsContact
					country={"United States of America"}
					address={["8 The Green, STE BDover, DE, 1990 1 USA."]}
					numbers={["+1 213-599-7399"]}
				/>

				<ContactUsContact
					country={"United Arab Emirates"}
					address={[
						"Office 115, Incubator Building, Masdar city Abu Dhabi, United Arab Emirates.",
						"Office 601-07, 6th floor, Bay Square, Business Bay, Dubai, UAE.",
					]}
					numbers={["+97124451337", "+971 45674907"]}
				/>

				<ContactUsContact
					country={"Canada"}
					address={[
						"4725 Sheppard Ave 1514, Toronto, Canada M1S5B2.",
					]}
					numbers={["404 667 3372"]}
				/>

				<ContactUsContact
					country={"Saudi Arabia"}
					address={["Dahran, Damam."]}
					numbers={["+96650482883"]}
				/>

				<ContactUsContact
					country={"India"}
					address={["C-52, Sector-2, Noida (U.P) India 201301"]}
					numbers={["+91 9837047518"]}
				/>
			</div>
		</div>
	);
};
