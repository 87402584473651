import React from "react";
import "./TrendsDetails.css";

export const TrendsDetails = ({
	design_type,
	header_title,
	header_img,
	header_trend_img,
	min_read,
	header_body,
	desc,
	references,
	top_results,
}) => {
	// const isMobile = useIsMobile();
	const headerStyle = {
		background: `url(${header_img})`,
	};
	// const circle1 = {
	// 	background:
	// 		design_type === "design_1"
	// 			? "radial-gradient(49.21% 54.09% at 50% 73.76%, rgba(34, 202, 142, 0.60) 0%, rgba(26, 142, 255, 0.60) 100%)"
	// 			: design_type === "design_2"
	// 			? "radial-gradient(41.76% 43.69% at 61.31% 71.55%, #24FFFF 0%, #009962 100%)"
	// 			: "radial-gradient(31.36% 34.56% at 49.9% 50%, #22CA8E 0%, #5315A2 100%)",
	// 	left:
	// 		design_type === "design_1"
	// 			? "calc(50vw - 50px)"
	// 			: design_type === "design_2"
	// 			? "calc(25vw - 50px)"
	// 			: "-50px",
	// 	top: design_type === "design_2" ? "-30px" : "10px",
	// 	...(isMobile ? { display: "none" } : {}),
	// };
	// const circle2 = {
	// 	background:
	// 		design_type === "design_1"
	// 			? "radial-gradient(49.21% 54.09% at 50% 73.76%, rgba(34, 202, 142, 0.60) 0%, rgba(26, 142, 255, 0.60) 100%)"
	// 			: design_type === "design_2"
	// 			? "radial-gradient(31.36% 34.56% at 49.9% 50%, #22CA8E 0%, #5315A2 100%)"
	// 			: "radial-gradient(41.76% 43.69% at 61.31% 71.55%, #24FFFF 0%, #009962 100%)",
	// 	left:
	// 		design_type === "design_1"
	// 			? "-60px"
	// 			: design_type === "design_2"
	// 			? "-60px"
	// 			: "100px",
	// 	bottom:
	// 		design_type === "design_1"
	// 			? "100px"
	// 			: design_type === "design_2"
	// 			? "50px"
	// 			: "100px",
	// 	...(isMobile ? { display: "none" } : {}),
	// };
	// const circle3 = {
	// 	background:
	// 		design_type === "design_1"
	// 			? "radial-gradient(41.76% 43.69% at 61.31% 71.55%, #24FFFF 0%, #009962 100%)"
	// 			: design_type === "design_2"
	// 			? "radial-gradient(49.21% 54.09% at 50% 73.76%, rgba(34, 202, 142, 0.60) 0%, rgba(26, 142, 255, 0.60) 100%)"
	// 			: "radial-gradient(31.36% 34.56% at 49.9% 50%, #22CA8E 0%, #5315A2 100%)",
	// 	left:
	// 		design_type === "design_1"
	// 			? "-100px"
	// 			: design_type === "design_2"
	// 			? "calc(100vw - 100px)"
	// 			: "-100px",
	// 	bottom:
	// 		design_type === "design_1"
	// 			? "0"
	// 			: design_type === "design_2"
	// 			? "20px"
	// 			: "20px",
	// 	...(isMobile ? { display: "none" } : {}),
	// };
	// const circle4 = {
	// 	background:
	// 		design_type === "design_1"
	// 			? "radial-gradient(31.36% 34.56% at 49.9% 50%, #22CA8E 0%, #5315A2 100%)"
	// 			: design_type === "design_2"
	// 			? "radial-gradient(41.76% 43.69% at 61.31% 71.55%, #24FFFF 0%, #009962 100%)"
	// 			: "radial-gradient(49.21% 54.09% at 50% 73.76%, rgba(34, 202, 142, 0.60) 0%, rgba(26, 142, 255, 0.60) 100%)",
	// 	left:
	// 		design_type === "design_1"
	// 			? "calc(100vw - 100px)"
	// 			: design_type === "design_2"
	// 			? "-100px"
	// 			: "calc(100vw - 100px)",
	// 	bottom:
	// 		design_type === "design_1"
	// 			? "0"
	// 			: design_type === "design_2"
	// 			? "20px"
	// 			: "100px",
	// 	...(isMobile ? { display: "none" } : {}),
	// };
	return (
		<div className="trends_page">
			<div className="head" style={headerStyle}>
				<p>{header_title}</p>
			</div>
			<div className="blog_img">
				<img src={header_trend_img} alt="header" />
			</div>
		</div>
	);
};
