import React from "react";

export const FooterContact = ({ country, numbers }) => {
	return (
		<div className="footer_contact">
			<p className="country">{country}</p>
			<p className="number">
				{(numbers ?? []).map((num, index, array) =>
					index === array.length - 1 ? (
						<a href={"tel:" + num}>{num}</a>
					) : (
						<React.Fragment>
							<a href={"tel:" + num}>{num}</a>
							&nbsp;&nbsp;&nbsp;&nbsp;
						</React.Fragment>
					)
				)}
			</p>
		</div>
	);
};
