import React from "react";
import "./HomePageFeatures.css";

export const HomePageFeatures = ({ bg, title, desc, url }) => {
	return (
		<div
			className="hp_feature_box"
			style={{ backgroundImage: `url(${bg})` }}
		>
			<div>
				<div className="flex">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="14"
						height="29"
						viewBox="0 0 14 29"
						fill="none"
					>
						<path
							d="M1.66406 4.5L12.4974 14.5L1.66406 24.5"
							stroke="#24FFFF"
							stroke-width="2.6"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
					<p className="title">{title}</p>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="14"
						height="29"
						viewBox="0 0 14 29"
						fill="none"
					>
						<path
							d="M12 4.5L2 14.5L12 24.5"
							stroke="url(#paint0_linear_1383_2182)"
							stroke-width="2.6"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<defs>
							<linearGradient
								id="paint0_linear_1383_2182"
								x1="2"
								y1="12.1899"
								x2="12.5963"
								y2="13.6988"
								gradientUnits="userSpaceOnUse"
							>
								<stop stop-color="#24FFFF" />
								<stop offset="1" stop-color="#1A8EFF" />
							</linearGradient>
						</defs>
					</svg>
				</div>
				<p className="desc">{desc}</p>
			</div>
			<div className="button_div">
				<div className="button">
					<a href={url}>Read More</a>
				</div>
			</div>
		</div>
	);
};
