import React, { useEffect, useRef, useState } from "react";
import "./ServicesDetails.css";
import { useIsMobile } from "../../utils/functions";

export const ServicesDetails = ({
	design_type,
	header_title,
	header_body,
	header_img,
	desc_1,
	desc_2_is_usecase,
	desc_2,
	desc_2_img,
	prev_url,
	next_url,
}) => {
	const isMobile = useIsMobile();
	const headerStyle = {
		background: `url(${header_img})`,
	};
	const circle1 = {
		background:
			design_type === "design_1"
				? "radial-gradient(49.21% 54.09% at 50% 73.76%, rgba(34, 202, 142, 0.60) 0%, rgba(26, 142, 255, 0.60) 100%)"
				: design_type === "design_2"
				? "radial-gradient(41.76% 43.69% at 61.31% 71.55%, #24FFFF 0%, #009962 100%)"
				: "radial-gradient(31.36% 34.56% at 49.9% 50%, #22CA8E 0%, #5315A2 100%)",
		left:
			design_type === "design_1"
				? "calc(50vw - 50px)"
				: design_type === "design_2"
				? "calc(25vw - 50px)"
				: "-50px",
		top: design_type === "design_2" ? "-30px" : "10px",
		...(isMobile ? { display: "none" } : {}),
	};
	const circle2 = {
		background:
			design_type === "design_1"
				? "radial-gradient(49.21% 54.09% at 50% 73.76%, rgba(34, 202, 142, 0.60) 0%, rgba(26, 142, 255, 0.60) 100%)"
				: design_type === "design_2"
				? "radial-gradient(31.36% 34.56% at 49.9% 50%, #22CA8E 0%, #5315A2 100%)"
				: "radial-gradient(41.76% 43.69% at 61.31% 71.55%, #24FFFF 0%, #009962 100%)",
		left:
			design_type === "design_1"
				? "-60px"
				: design_type === "design_2"
				? "-60px"
				: "100px",
		bottom:
			design_type === "design_1"
				? "100px"
				: design_type === "design_2"
				? "50px"
				: "100px",
		...(isMobile ? { display: "none" } : {}),
	};
	const circle3 = {
		background:
			design_type === "design_1"
				? "radial-gradient(41.76% 43.69% at 61.31% 71.55%, #24FFFF 0%, #009962 100%)"
				: design_type === "design_2"
				? "radial-gradient(49.21% 54.09% at 50% 73.76%, rgba(34, 202, 142, 0.60) 0%, rgba(26, 142, 255, 0.60) 100%)"
				: "radial-gradient(31.36% 34.56% at 49.9% 50%, #22CA8E 0%, #5315A2 100%)",
		left:
			design_type === "design_1"
				? "-100px"
				: design_type === "design_2"
				? "calc(100vw - 100px)"
				: "-100px",
		bottom:
			design_type === "design_1"
				? "0"
				: design_type === "design_2"
				? "20px"
				: "20px",
		...(isMobile ? { display: "none" } : {}),
	};
	const circle4 = {
		background:
			design_type === "design_1"
				? "radial-gradient(31.36% 34.56% at 49.9% 50%, #22CA8E 0%, #5315A2 100%)"
				: design_type === "design_2"
				? "radial-gradient(41.76% 43.69% at 61.31% 71.55%, #24FFFF 0%, #009962 100%)"
				: "radial-gradient(49.21% 54.09% at 50% 73.76%, rgba(34, 202, 142, 0.60) 0%, rgba(26, 142, 255, 0.60) 100%)",
		left:
			design_type === "design_1"
				? "calc(100vw - 100px)"
				: design_type === "design_2"
				? "-100px"
				: "calc(100vw - 100px)",
		bottom:
			design_type === "design_1"
				? "0"
				: design_type === "design_2"
				? "20px"
				: "100px",
		...(isMobile ? { display: "none" } : {}),
	};
	const [maxHeights, setMaxHeights] = useState([]);
	const bodyRefs = useRef({});
	useEffect(() => {
		const calculateMaxHeights1 = () => {
			document
				.querySelectorAll(".desc_2 .body_col .body")
				.forEach((b) => (b.style.height = ""));

			document
				.querySelectorAll(
					".desc_2 .side_body.main .hr-hori:not(.mobile_only)"
				)
				.forEach((element) => element.remove());
			if (isMobile) {
				return;
			}
			const newMaxHeights = [];
			const elements = document.querySelectorAll(
				".desc_2 .body_col:first-child .body"
			);
			for (var row = 0; row < elements.length; row++) {
				const heights = [];
				for (var col = 0; col < 2; col++) {
					const index = row * 2 + col;
					const element = bodyRefs.current[index];
					if (element) {
						heights.push(element.offsetHeight);
					}
				}
				newMaxHeights[row] = Math.max(...heights);
			}
			for (var _row = 0; _row < elements.length; _row++) {
				for (var _col = 0; _col < 2; _col++) {
					const index = _row * 2 + _col;
					const element = bodyRefs.current[index];
					if (element) {
						element.style.height = `${newMaxHeights[_row]}px`;
					}
				}
			}
			setMaxHeights(newMaxHeights);

			let totalHeight = 0;
			const parent = document.querySelector(".desc_2 .side_body.main");
			for (var row1 = 0; row1 < elements.length - 1; row1++) {
				totalHeight += newMaxHeights[row1];
				const height = totalHeight - 75 / 2;
				const style = `position:absolute;left:0;top:${height}px;`;
				const newContent = `<div class="hr-hori show" style="${style}"></div>`;
				parent.insertAdjacentHTML("afterbegin", newContent);
			}
		};
		calculateMaxHeights1();
		window.addEventListener("resize", calculateMaxHeights1);
		return () => window.removeEventListener("resize", calculateMaxHeights1);
	}, [desc_2, isMobile]);
	return (
		<div className="service_page">
			<div className="service_header">
				<div className="header_bg" style={headerStyle} />
				<div className="header_circle1" style={circle1} />
				<div className="header_circle2" style={circle2} />
				<div className="header_parent">
					<p className="title">{header_title}</p>
					<p className="body">{header_body}</p>
				</div>
			</div>
			<div className="hr-hori"></div>
			<div className="desc_1 flex">
				<div className="circle1" style={circle3}></div>
				<div className="desc">How can we serve you?</div>
				<div className="side_body">
					{desc_1?.map((desc) => (
						<div className="body">
							<div className="flex">
								<div className="icon">{desc?.icon}</div>
								<div className="title">{desc?.title}</div>
							</div>
							<div className="t_body">{desc?.body}</div>
						</div>
					))}
				</div>
			</div>
			<div className="desc_2 flex">
				<div
					className="bg"
					style={{ background: `url(${desc_2_img})` }}
				></div>
				<div className="desc">
					{desc_2_is_usecase
						? "Use Cases"
						: "Reasons to count on us!"}
					{desc_2_is_usecase && !isMobile && (
						<p>We make a difference in every scenario possible.</p>
					)}
				</div>
				<div className="side_body main">
					<div className="side_body">
						<div className="body_col">
							{desc_2
								?.filter((_, index) => index % 2 === 0)
								.map((desc, rowIdx) => (
									<>
										<div
											className="body"
											ref={(el) =>
												(bodyRefs.current[
													rowIdx * 2 + 0
												] = el)
											}
											style={
												maxHeights[rowIdx]
													? {
															height: `${maxHeights[rowIdx]}px`,
													  }
													: {}
											}
										>
											<div className="flex title_parent">
												<div className="title">
													{desc?.title}
												</div>
												<div>{desc?.icon}</div>
											</div>
											<div className="t_body">
												{desc?.body}
											</div>
										</div>
										<div className="hr-hori mobile_only"></div>
									</>
								))}
						</div>
						<div className="body_col">
							{desc_2
								?.filter((_, index) => (index - 1) % 2 === 0)
								.map((desc, rowIdx) => (
									<>
										<div
											className="body"
											ref={(el) =>
												(bodyRefs.current[
													rowIdx * 2 + 1
												] = el)
											}
											style={
												maxHeights[rowIdx]
													? {
															height: `${maxHeights[rowIdx]}px`,
													  }
													: {}
											}
										>
											<div className="flex title_parent">
												<div className="title">
													{desc?.title}
												</div>
												<div>{desc?.icon}</div>
											</div>
											<div className="t_body">
												{desc?.body}
											</div>
										</div>
										<div className="hr-hori mobile_only"></div>
									</>
								))}
						</div>
					</div>
				</div>
			</div>
			{
				<div className="service_footer">
					<div className="circle1" style={circle4}></div>
					<div
						className="flex"
						style={{
							margin: "auto auto 40px auto",
						}}
					>
						<div className="prev_button_div">
							<button className="prev_button">
								<a href={prev_url}>Prev</a>
							</button>
						</div>
						<div style={{ marginRight: "30px" }}></div>
						<a href={next_url}>
							<button className="next_button">Next</button>
						</a>
					</div>
				</div>
			}
			<div className="hr-hori"></div>
		</div>
	);
};
