import React from "react";

export const ContactUsContact = ({ country, address, numbers }) => {
	return (
		<div className="contactus_contact">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="15"
				height="15"
				viewBox="0 0 15 15"
				fill="none"
			>
				<circle
					cx="7.5"
					cy="7.5"
					r="7.5"
					fill="url(#paint0_linear_703_300)"
				/>
				<defs>
					<linearGradient
						id="paint0_linear_703_300"
						x1="-2.68787e-08"
						y1="6.4557"
						x2="15"
						y2="9.56487"
						gradientUnits="userSpaceOnUse"
					>
						<stop stop-color="#24FFFF" />
						<stop offset="0.739583" stop-color="#114FAB" />
						<stop offset="1" stop-color="#1A8EFF" />
					</linearGradient>
				</defs>
			</svg>
			<p className="country">{country}</p>
			{(numbers ?? []).map((num, index, array) => (
				<div className="contact_div">
					<p className="address">{address[index]}</p>
					<p className="number">
						<a href={"tel:" + numbers[index]}>{numbers[index]}</a>
					</p>
				</div>
			))}
		</div>
	);
};
