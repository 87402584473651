import React from "react";
import { GradientButton } from "../GradientButton/GradientButton";
import "./HoverCard.css";
import { useIsMobile } from "../../utils/functions";

export const HoverCard = ({ image, title, desc, border, className, url }) => {
	const isMobile = useIsMobile();
	const bgStyle = {
		background: !(className || "").includes("rightHoverCard")
			? `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.90) 100%), url(${image})`
			: `linear-gradient(90deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.90) 91.15%), url(${image}), lightgray -11px -216.704px / 101.302% 225.678% no-repeat`,
	};
	const cardStyle = {
		borderRightWidth: border?.includes("right") ? "1px" : "0",
		borderLeftWidth: border?.includes("left") ? "1px" : "0",
		borderTopWidth: border?.includes("top") ? "1px" : "0",
		borderBottomWidth: border?.includes("bottom") ? "1px" : "0",
	};
	if (className) {
		className = "hover_card " + className;
	} else {
		className = "hover_card";
	}
	return (
		<a className={className} style={cardStyle} href={isMobile ? url : null}>
			<div className="hover_card_bg" style={bgStyle}></div>
			<div className="hover_card_content">
				<div className="main_div">
					<p className="title">{title}</p>
					<p className="desc">{desc}</p>
					<div className="button_hover">
						<GradientButton text="Learn More" url={url} />
					</div>
				</div>
			</div>
		</a>
	);
};
