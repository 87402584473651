import React from "react";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";

const Layout = ({ children, nofooter }) => {
	return (
		<>
			<Header className="sticky" />
			{children}
			{!nofooter && <Footer />}
		</>
	);
};

export default Layout;
